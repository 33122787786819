import React, {Fragment} from 'react';
import * as pt from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import {AD_TOPLINE_BANNER_ID} from 'constants/ad';

import Ad from 'components/Ad';

import Topline from '@rambler-components/topline';
import toplineStyles from '@rambler-components/topline/styles.css';

import top100Wrapper from 'core/top100Wrapper';

// TODO - пока релизим без виджетов
// import icons from './icons';
// import SunriseIcon from './i/sunrise.svg';

import s from './index.scss';

class TopPartComponent extends React.Component {
  static propTypes = {
    toggleModalVisibility: pt.func.isRequired,
    isNeedBanner:          pt.bool,
  }

  static defaultProps = {
    isNeedBanner: false,
  }

  onUserId = (userId) => {
    top100Wrapper.sendUserId(userId);
  }

  render() {
    const {isNeedBanner, toggleModalVisibility} = this.props;


    return (
      <Fragment>
        <Topline
          projectCode="weather"
          withWeather={false}
          withExchangeRates={false}
          promoAd={isNeedBanner ? <Ad id={AD_TOPLINE_BANNER_ID} /> : null}
          onUserFetched={this.onUserFetched}
          onUserId={this.onUserId}
          onOpenGeo={() => toggleModalVisibility(true)}

        >
          {/** TODO - пока релизим без виджетов */}
          {/* <Fragment>
            <a
              href="https://horoscopes.rambler.ru/moon/calendar/"
              target="_blank"
              className={s.moonContainer}
              data-weather={`${page}::header_moon`}
              title={header.title}
            >
              <span className={s.moon}>
                <span className={s.moonIconContainer}>
                  {withIcon && <Element className={s.moonIcon} />}
                </span> <span className={s.moonText}>{header.title}</span>
              </span>
            </a>
            <Link
              to={`/${urlPath}/today/`}
              className={s.statusContainer}
              data-weather={`${page}::header_sunrise_sunset`}
            >
              <span className={s.status} title="Восход">
                <SunriseIcon className={s.icon} /> {moment(header.sunrise).format('HH:mm')}
              </span>
              <span className={s.status} title="Закат">
                <SunsetIcon className={s.icon} /> {moment(header.sunset).format('HH:mm')}
              </span>
            </Link>
          </Fragment> */}
        </Topline>
      </Fragment>
    );
  }
}

export const TopPartWithoutHocs = TopPartComponent;
export default withStyles(s, toplineStyles)(TopPartComponent);
